import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const AppLogo = () => {
  const useStyles = makeStyles(() => ({
    logoRoot: {
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 55,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <Hidden smUp>
        <img
          className={classes.logo}
          src="/assets/images/Advoguide_Logo_horiz.svg"
          alt="crema-logo"
        />
      </Hidden>
      <Hidden xsDown>
        <img
          className={classes.logo}
          src="/assets/images/Advoguide_Logo_horiz.svg"
          alt="crema-logo"
        />
      </Hidden>
    </Box>
  );
};

export default AppLogo;
