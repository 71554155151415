import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import { useAuthToken } from '../../../utility/AppHooks';

import routesConfig, { NavItemProps } from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import { renderPermittedMenus } from 'shared/helpers/renderPermittedMenus';
import { NotificationProvider } from './Context';

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const [loading, user] = useAuthToken();
  const [permittedRoutes, setPermittedRoutes] = useState<NavItemProps[]>(
    [] as NavItemProps[]
  );

  useEffect(() => {
    if (!loading && user) {
      setPermittedRoutes(
        renderPermittedMenus(
          user.permissions.map((permission) => permission.slug),
          routesConfig
        )
      );
    }
  }, [loading, user]);

  return (
    <NotificationProvider>
      <List>
        {permittedRoutes.map((item: NavItemProps) => (
          <React.Fragment key={item.id}>
            {item.type === 'group' && (
              <VerticalNavGroup item={item} level={0} />
            )}

            {item.type === 'collapse' && (
              <VerticalCollapse item={item} level={0} />
            )}

            {item.type === 'item' && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ))}
      </List>
    </NotificationProvider>
  );
};

export default Navigation;
