export const TOKEN_KEY = 'app_token';

export default class Vault {
  public static getAccessToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public static setAccessToken(token: string) {
    return window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public static clearAccessToken() {
    window.sessionStorage.removeItem(TOKEN_KEY);
  }
}
