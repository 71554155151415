import { ApiUrlsAddresses } from 'shared/constants';
import { IUserInfo } from 'shared/interfaces/IUserInfo';
import { Http } from 'shared/lib';

export class UserInfo {
  async index(filters: any): Promise<IUserInfo | null> {
    const { data, error } = await Http.get<IUserInfo>(
      ApiUrlsAddresses.userinfo.getList(),
      undefined,
      filters
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const userInfoService = new UserInfo();
