// @ts-ignore
import Ws from '@adonisjs/websocket-client/index';

export async function SocketConnection() {
  const baseURL = process.env.REACT_APP_API_BASE_URL_WS;
  const ws = Ws(baseURL).connect();

  const notification =
    ws.getSubscription('notification') || ws.subscribe('notification');

  return notification;
}

export async function SocketFetchData(topic: any) {
  const dataPromise = new Promise((resolve) => {
    topic.on('notification', (event: any) => resolve(event));
  });

  const data = (await dataPromise) as any;

  return data;
}
