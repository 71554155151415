import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import useStyles from "./AppHeader.style";
import AppLogo from "../../../../shared/components/AppLogo";
import clsx from "clsx";
import { toggleNavCollapsed, useLayoutActionsContext } from "../LayoutContextProvider";

interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useLayoutActionsContext()!;

  return (
    <>
      <AppBar style={{ backgroundColor: '#2d313c' }} className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(toggleNavCollapsed())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
          <AppLogo />
        </Toolbar>
      </AppBar>
    </>
  );
};
export default AppHeader;
