// import React from 'react';

export const linkRequestPagesConfig = [
  {
    auth: ['user'],
    routes: [
      // {
      //   path: '/requests/services',
      //   component: React.lazy(
      //     () => import('./PublicServices/Pages/PublicServiceList')
      //   ),
      // },
      // {
      //   path: '/requests/service/:secure_id',
      //   component: React.lazy(
      //     () => import('./PublicServices/Pages/PublicServiceView')
      //   ),
      // },
      // {
      //   path: '/requests/agents',
      //   component: React.lazy(
      //     () => import('./PublicAgents/Pages/PublicAgentsList')
      //   ),
      // },
      // {
      //   path: '/requests/agent/:secure_id',
      //   component: React.lazy(
      //     () => import('./PublicAgents/Pages/PublicAgentView')
      //   ),
      // },
    ],
  },
];
