import { NavItemProps } from 'modules/routesConfig';

export function renderPermittedMenus(
  permissions: string[],
  routesConfig: NavItemProps[]
): NavItemProps[] {
  const menu: any = {
    dash: 'dash',
    agents_and_services: 'public-agents-and-services',
    manage_users: 'management-users-app',
    link_request: 'link-requests',
    moderation: 'moderation',
    faq: 'FAQ',
    contact_and_report: 'contact-and-report',
    rating: 'rating-criteria',
    plans: '',
    financial: '',
    advertisements: 'advertisements',
  };

  const permittedMenuKeys = ['management-administrators'];

  permittedMenuKeys.push(...permissions.map((permission) => menu[permission]));

  const permittedMenuAccess = routesConfig.filter((permittedMenu) =>
    permittedMenuKeys.some((key) => key === permittedMenu.id)
  );

  return permittedMenuAccess || ([] as NavItemProps[]);
}
