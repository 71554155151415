import IApplicationConfigAdapter from './IApplicationConfigAdapter'
import ProdConfigAdapter from './impl/ProdConfigAdatpter'
import DevConfigAdapter from './impl/DevConfigAdapter'
import TestConfigAdapter from './impl/TestConfigAdapter'

let instance: IApplicationConfigAdapter | null = null

export default (() => {
  if (!instance) {
    switch (process.env.NODE_ENV) {
      case 'production':
        instance = new ProdConfigAdapter()
        break
      case 'test':
        instance = new TestConfigAdapter()
        break
      default:
        instance = new DevConfigAdapter()
        break
    }
  }

  return instance
})()
