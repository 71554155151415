import enMessages from '../locales/pt_BR.json'
import { ptBR } from '@material-ui/core/locale'

const ptBrLang = {
  messages: {
    ...enMessages,
  },
  muiLocale: ptBR,
  locale: 'pt-BR',
}
export default ptBrLang
