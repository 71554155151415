import { ApiUrlsAddresses } from 'shared/constants';
import { IError, IPublicAgent, IPublicAgents } from 'shared/interfaces';
import { Http } from 'shared/lib';

class PublicAgentServices {
  async index(
    page: number = 1,
    perPage: number = 5,
    filters: any,
    isLinkedUsers?: boolean
  ): Promise<IPublicAgents | null> {
    const { data, error } = await Http.get<IPublicAgents>(
      ApiUrlsAddresses.publicAgents.getList(page, perPage, isLinkedUsers),
      undefined,
      filters
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getNoPagination(): Promise<IPublicAgent[] | null> {
    const { data, error } = await Http.get<IPublicAgent[]>(
      ApiUrlsAddresses.publicAgents.getAll({})
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getNoLinkedUser(): Promise<IPublicAgent[] | null> {
    const { data, error } = await Http.get<IPublicAgent[]>(
      ApiUrlsAddresses.publicAgents.getAll({ isUserLinked: false })
    );
    if (error) {
      return error;
    }
    return data;
  }

  async show(id: string): Promise<IPublicAgent | null> {
    const { data, error } = await Http.get<IPublicAgent>(
      ApiUrlsAddresses.publicAgents.getById(id)
    );
    if (error) {
      return error;
    }
    return data;
  }
  async store(newUser: IPublicAgent): Promise<any | IError | null> {
    const { status, error } = await Http.post<IPublicAgent, IError>(
      ApiUrlsAddresses.publicAgents.create(),
      newUser
    );
    if (error) {
      return error;
    }
    return status;
  }
  async update(user: IPublicAgent): Promise<any | IError | null> {
    const { status, error } = await Http.put<IPublicAgent, IPublicAgent>(
      ApiUrlsAddresses.publicAgents.update(user.secure_id || ''),
      user
    );
    if (error) {
      return error;
    }
    return status;
  }
  async destroy(user_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IPublicAgent>(
      ApiUrlsAddresses.publicAgents.destroy(user_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }

  async destroyDefinitely(user_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IPublicAgent>(
      ApiUrlsAddresses.publicAgents.destroyDefinitely(user_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }
}

export const publicAgentServices = new PublicAgentServices();
