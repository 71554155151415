import { ApiUrlsAddresses } from 'shared/constants';
import { IUser, IUsers } from 'shared/interfaces';
import { IUserFilters } from 'shared/interfaces/IUser';
import { Http } from 'shared/lib';

export class UserServices {
  async index(
    page: number = 1,
    perPage: number = 5,
    filters: IUserFilters
  ): Promise<IUsers | null> {
    const { data, error } = await Http.get<IUsers>(
      ApiUrlsAddresses.users.getList(page, perPage, filters)
    );

    if (error) {
      return error;
    }
    return data;
  }

  async show(id: string): Promise<IUser | null> {
    const { data, error } = await Http.get<IUser>(
      ApiUrlsAddresses.users.getById(id)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getAll(filters: IUserFilters): Promise<IUser[] | null> {
    const { data, error } = await Http.get<IUser[]>(
      ApiUrlsAddresses.users.getAll(filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getPermissions(role_id: string): Promise<any | null> {
    const { data, error } = await Http.get<any[]>(
      ApiUrlsAddresses.users.getPermissions(role_id)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async store(newUser: IUser): Promise<number | any> {
    const { status, error } = await Http.post<IUser, any>(
      ApiUrlsAddresses.users.create(),
      newUser
    );
    if (error) {
      return error;
    }
    return status;
  }

  async update(user: IUser): Promise<number | any> {
    const { status, error } = await Http.put<IUser, IUser>(
      ApiUrlsAddresses.users.update(user.secure_id || ''),
      user
    );
    if (error) {
      return error;
    }
    return status;
  }

  async link(data: {
    status: string;
    agent_id?: number;
    service_id?: number;
    secure_id: string;
  }): Promise<number | any> {
    const { status, error } = await Http.put<any, any>(
      ApiUrlsAddresses.users.update(data.secure_id || ''),
      data
    );
    if (error) {
      return error;
    }
    return status;
  }

  async destroy(user_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IUser>(
      ApiUrlsAddresses.users.destroy(user_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }

  async destroyDefinitely(user_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IUser>(
      ApiUrlsAddresses.users.destroyDefinitely(user_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }
}

export const usersServices = new UserServices();
