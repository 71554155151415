import { ApiUrlsAddresses } from 'shared/constants';
import { IFAQCategory } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class FAQCategoryServices {

  async getAllCategories(): Promise<IFAQCategory[] | null> {
    const { data, error } = await Http.get<IFAQCategory[]>(
      ApiUrlsAddresses.FAQCategories.index()
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const faqCategoryServices = new FAQCategoryServices();