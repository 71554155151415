interface ISelectedFilters {
  [key: string]: string[];
}
class FiltersColumns {
  clearValueFilters(element: string, filters: any, setFilters: Function) {
    const removedProp: any = [`filters[${element}]`];

    const { [removedProp]: removedProperty, ...rest } = filters;
    setFilters(rest);
  }

  addValuesFilters(
    filters: Object,
    element: string,
    value: string[],
    setFilters: Function
  ) {
    if (value) {
      setFilters({
        ...filters,
        [`filters[${element}]`]: [...value],
      });
      return;
    }
    this.clearValueFilters(element, filters, setFilters);
    return;
  }

  verifyTypeFilters(
    filters: Object,
    selectedFilters: ISelectedFilters,
    setFilters: Function
  ) {
    const arrayOfKeys = Object.keys(selectedFilters);
    arrayOfKeys.forEach((element: string) => {
      switch (element) {
        case 'judicial_role':
          this.addValuesFilters(
            filters,
            'role',
            selectedFilters[element],
            setFilters
          );
          break;
        case 'address':
          this.addValuesFilters(
            filters,
            'state',
            selectedFilters[element],
            setFilters
          );
          break;
        default:
          this.addValuesFilters(
            filters,
            element,
            selectedFilters[element],
            setFilters
          );
          break;
      }
    });
  }
}

export const filtersColumns = new FiltersColumns();
