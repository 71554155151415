import React, { ReactNode, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import { useAuthToken } from './AppHooks';
import { Loader } from '../index';
import { checkPermission, checkUserPermission } from './Utils';
import { initialUrl } from '../../shared/constants/AppConst';
import AppContextPropsType from '../../types/AppContextPropsType';
import {
  NavStyle,
  ThemeMode,
  ThemeStyle,
} from '../../shared/constants/AppEnums';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    initialPath,
    setInitialPath,
    updateThemeMode,
    setRTL,
  } = useContext<AppContextPropsType>(AppContext);

  const [loading, user] = useAuthToken();
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  const isPermitted = checkPermission(
    currentRoute.auth,
    user ? user.role : null
  );

  const isPermittedRoute = checkUserPermission(
    pathname,
    user?.permissions.map((permission) => permission.slug)
  );

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/error-pages/error-404',
          '/forget-password',
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          setInitialPath(pathname);
        } else {
          setInitialPath(undefined);
        }
      }
    }

    setInitPath();
  }, [isPermitted, setInitialPath, initialPath, pathname]);

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout as NavStyle);
      }
      if (query.mode) {
        updateThemeMode(query.mode as ThemeMode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle!(query.style as ThemeStyle);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeMode, setRTL, updateThemeStyle, search]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!user && !isPermitted) {
      history.push('/signin');
      return;
    }

    if (user && !isPermitted) {
      history.push('/error-pages/error-404');
      return;
    }

    if (user && isPermitted) {
      if (
        pathname === '/' ||
        pathname === '/signin' ||
        pathname === '/signup'
      ) {
        history.push(initialUrl);
        return;
      }
      if (initialPath && initialUrl !== initialPath && initialPath !== '/') {
        history.push(initialPath);
        return;
      }

      if (!isPermittedRoute) {
        history.push('/error-pages/error-404');
        return;
      }
    }
  }, [
    history,
    initialPath,
    isPermitted,
    loading,
    pathname,
    isPermittedRoute,
    user,
  ]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
