import { INotification } from 'shared/interfaces';

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  notification?: keyof INotification;
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'dash',
    title: 'Dashboard',
    messageId: 'sidebar.app.dashboard',
    type: 'item',
    url: '/dashboard',
    icon: 'dashboard',
  },
  {
    id: 'management-administrators',
    title: 'management administrators',
    messageId: 'sidebar.sub_page.managementAdmin',
    type: 'item',
    url: '/management-admins',
    icon: 'groups',
  },
  // {
  //   id: 'public-agents-and-services',
  //   title: 'public-agents-and-services',
  //   messageId: 'sidebar.publicAgentsAndServices',
  //   type: 'collapse',
  //   icon: 'supervisor_account',
  //   children: [
  //     {
  //       id: 'agents',
  //       title: 'agents',
  //       messageId: 'sidebar.sub_page.agents',
  //       type: 'item',
  //       url: '/public/agents',
  //       icon: 'gavel',
  //     },
  //     {
  //       id: 'services',
  //       title: 'services',
  //       messageId: 'sidebar.sub_page.services',
  //       type: 'item',
  //       url: '/public/services',
  //       icon: 'domain',
  //     },
  //   ],
  // },
  {
    id: 'management-users-app',
    title: 'management-users-app',
    messageId: 'sidebar.managementUsersApp',
    type: 'collapse',
    icon: 'phone_iphone',
    children: [
      {
        id: 'userLawyers',
        title: 'lawyers',
        messageId: 'sidebar.sub_page.lawyers',
        type: 'item',
        url: '/users-app/lawyers',
        icon: 'work',
        notification: 'pendingLawyers',
      },
      // {
      //   id: 'userAgents',
      //   title: 'agents',
      //   messageId: 'sidebar.sub_page.agents',
      //   type: 'item',
      //   url: '/users-app/agents',
      //   icon: 'gavel',
      //   notification: 'pendingAgents',
      // },
      // {
      //   id: 'userServices',
      //   title: 'services',
      //   messageId: 'sidebar.sub_page.services',
      //   type: 'item',
      //   url: '/users-app/services',
      //   icon: 'domain',
      //   notification: 'pendingServices',
      // },
    ],
  },

  // {
  //   id: 'link-requests',
  //   title: 'link-requests',
  //   messageId: 'sidebar.linkRequests',
  //   type: 'collapse',
  //   icon: 'link',
  //   children: [
  //     {
  //       id: 'agents',
  //       title: 'agents',
  //       messageId: 'sidebar.sub_page.agents',
  //       type: 'item',
  //       url: '/requests/agents',
  //       icon: 'gavel',
  //     },
  //     {
  //       id: 'services',
  //       title: 'services',
  //       messageId: 'sidebar.sub_page.services',
  //       type: 'item',
  //       url: '/requests/services',
  //       icon: 'domain',
  //     },
  //   ],
  // },
  {
    id: 'moderation',
    title: 'moderation',
    messageId: 'sidebar.moderation',
    type: 'collapse',
    icon: 'question_answer',
    children: [
      // {
      //   id: 'evaluations',
      //   title: 'evaluations',
      //   messageId: 'sidebar.sub_page.evaluations',
      //   type: 'item',
      //   url: '/moderation/evaluations',
      //   icon: 'fact_check',
      //   notification: 'pendingEvaluations',
      // },
      {
        id: 'posts',
        title: 'posts',
        messageId: 'sidebar.sub_page.posts',
        type: 'item',
        url: '/moderation/posts',
        icon: 'fact_check',
        notification: 'pendingPosts',
      },
      {
        id: 'comments',
        title: 'comments',
        messageId: 'sidebar.sub_page.comments',
        type: 'item',
        url: '/moderation/comments',
        icon: 'comments',
        notification: 'pendingCommentsTypeCommon',
      },
      // {
      //   id: 'replicas',
      //   title: 'replicas',
      //   messageId: 'sidebar.sub_page.replicas',
      //   type: 'item',
      //   url: '/moderation/replicas',
      //   icon: 'stars',
      //   notification: 'pendingCommentsTypeReply',
      // },
      // {
      //   id: 'rejoinders',
      //   title: 'rejoinders',
      //   messageId: 'sidebar.sub_page.rejoinders',
      //   type: 'item',
      //   url: '/moderation/rejoinders',
      //   icon: 'stars',
      //   notification: 'pendingCommentsTypeRejoinder',
      // },
    ],
  },
  {
    id: 'FAQ',
    title: 'FAQ',
    messageId: 'sidebar.pages.extraPages.faq',
    type: 'item',
    url: '/faq/faq-list',
    icon: 'quiz',
  },

  {
    id: 'contact-and-report',
    title: 'contact-and-report',
    messageId: 'sidebar.sub_page.contact-and-report',
    type: 'collapse',
    icon: 'question_answer',
    children: [
      {
        id: 'Report',
        title: 'Report',
        messageId: 'sidebar.sub_page.report',
        type: 'item',
        url: '/reports',
        icon: 'campaign',
        notification: 'pendingReports',
      },
      {
        id: 'Contacts',
        title: 'Contacts',
        messageId: 'common.contact',
        type: 'item',
        url: '/contacts',
        icon: 'forum',
        notification: 'pendingContacts',
      },
      {
        id: 'Categories',
        title: 'Contact',
        messageId: 'dashboard.categories',
        type: 'item',
        url: '/contacts-and-reports/categories',
        icon: 'category',
      },
    ],
  },
  // {
  //   id: 'rating-criteria',
  //   title: 'rating criteria',
  //   messageId: 'sidebar.mui.util.rating',
  //   type: 'item',
  //   url: '/ratings',
  //   icon: 'stars',
  // },
  {
    id: 'advertisements',
    title: 'advertisements',
    messageId: 'sidebar.pages.advertisements',
    type: 'item',
    url: '/advertisements',
    icon: 'assessment',
  },
];
export default routesConfig;
