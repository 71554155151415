import { ApiUrlsAddresses } from 'shared/constants';
import { INotification } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class Notification {
  async index(filters: any): Promise<INotification | null> {
    const { data, error } = await Http.get<INotification>(
      ApiUrlsAddresses.notification.getList(),
      undefined,
      filters
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const notificationService = new Notification();
