import { ApiUrlsAddresses } from 'shared/constants';
import { IRatingCategory } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class RatingCategoryServices {
  async getAllCategories(): Promise<IRatingCategory[] | null> {
    const { data, error } = await Http.get<IRatingCategory[]>(
      ApiUrlsAddresses.ratingCategories.index()
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const ratingCategoryServices = new RatingCategoryServices();
