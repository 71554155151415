import React, { createContext, useContext, useEffect, useState } from 'react';
import { INotification } from 'shared/interfaces';
import { notificationService } from 'shared/services';
import { NotificationContextProps, NotificationProps } from './interface';
import { SocketConnection, SocketFetchData } from 'shared/lib/socket';

export const NotificationContext = createContext<NotificationContextProps>(
  {} as NotificationContextProps
);

export function NotificationProvider({ children }: NotificationProps) {
  const [notificationData, setNotificationData] = useState<INotification>(
    {} as INotification
  );

  useEffect(() => {
    (async () => {
      const topic = await SocketConnection();
      const data = await SocketFetchData(topic);
      setNotificationData(data);
    })();
  }, [notificationData]);

  useEffect(() => {
    setTimeout(() => {
      fetchDashboardData();
    }, 500);
  }, []);

  async function fetchDashboardData(filters?: any) {
    try {
      await notificationService.index(filters);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        notificationData,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotificationContext() {
  const context = useContext(NotificationContext);
  return context;
}
