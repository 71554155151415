import React from 'react';

export const advertisementsPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/advertisements',
        component: React.lazy(() => import('./Pages/AdvertisementsList')),
      },
      {
        path: '/advertisement',
        component: React.lazy(() => import('./Pages/AdvertisementsForm')),
      },
      {
        path: '/advertisement-view/:secure_id',
        component: React.lazy(() => import('./Pages/AdvertisementsForm')),
      },
    ],
  },
];
