export function translateTypesServices(field: string): string {
  const fields: any = {
    civil_registry: 'Registro Cívil',
    notarys_office: 'Cartório de Notas',
    property_registry: 'Registro de Imóveis',
    protest_notaries: 'Tabelionatos de protestos',
    company_registry: 'Registro Civil de Títulos e das Pessoas Jurídicas',
    criminal: 'Criminal',
    birth: 'Nascimentos',
    weddings: 'Casamentos',
    others: 'Outros',
  };
  return fields[field] || field;
}
