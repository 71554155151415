import { ApiUrlsAddresses } from 'shared/constants';
import { IPosts, IPostsFilters, IPost } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class PostsServices {
  async index(
    page: number = 1,
    perPage: number = 5,
    filters: IPostsFilters = { user: '', status: '', message: '' }
  ): Promise<IPosts | null> {
    const { data, error } = await Http.get<IPosts>(
      ApiUrlsAddresses.posts.getList(page, perPage, filters)
    );
    if (error) {
      return error;
    }
    return data;
  }
  async getAll(
    filters: IPostsFilters = { user: '', status: '', message: '' }
  ): Promise<IPost[] | null> {
    const { data, error } = await Http.get<IPost[]>(
      ApiUrlsAddresses.posts.getAll(filters)
    );
    if (error) {
      return error;
    }
    return data;
  }
  async show(post_id: string): Promise<IPost | null> {
    const { data, error } = await Http.get<IPost>(
      ApiUrlsAddresses.posts.getById(post_id)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async update(post: IPost): Promise<number | any> {
    const { status, error } = await Http.put<IPost, IPost>(
      ApiUrlsAddresses.posts.update(post.secure_id || ''),
      post
    );

    if (error) {
      return error;
    }
    return status;
  }
}

export const postsServices = new PostsServices();
