import React from 'react';

export const managementAdminsPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/management-admins',
        component: React.lazy(() => import('./Pages/ManagementAdminList')),
      },
      {
        path: '/management-admin/change-password/:user_id',
        component: React.lazy(
          () => import('./Pages/ManagementAdminResetPassword')
        ),
      },
      {
        path: '/management-admin/:user_id',
        component: React.lazy(() => import('./Pages/ManagementAdminForm')),
      },
      {
        path: '/management-admin-new',
        component: React.lazy(() => import('./Pages/ManagementAdminForm')),
      },
    ],
  },
];
