import IApplicationConfigAdapter from '../IApplicationConfigAdapter'
import IApplicationSettings, { NODE_ENV } from '../IApplicationSettings'

export default class ProdConfigAdapter implements IApplicationConfigAdapter {
  settings!: IApplicationSettings

  constructor() {
    this.config()
  }

  config(): void {
    this.settings = {
      nodeEnv: process.env.NODE_ENV as NODE_ENV,
      port: parseInt(process.env.PORT || '4000', 10),
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string
    }
  }

  getSetting(
    key: keyof IApplicationSettings
  ): IApplicationSettings[keyof IApplicationSettings] {
    return this.settings[key]
  }

  getSettings(): IApplicationSettings {
    return this.settings
  }
}
