export function translateJudicialRolesAgents(field: string): string {
  const fields: any = {
    judge: 'Juiz',
    substitute_judge: 'Juiz substituto',
    high_judge: 'Desembargador',
    minister: 'Ministro',
  };
  return fields[field] || field;
}

export function translateCourtFieldInAgents(field: string): string {
  const fields: any = {
    state: 'Estadual',
    federal: 'Federal',
    labor: 'Trabalhista',
    stf: 'STF',
    stj: 'STJ',
  };

  return fields[field] || field;
}
