import { ApiUrlsAddresses } from 'shared/constants';
import { IDashboard, IGraphsData } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class Dashboard {
  async index(filters: any): Promise<IDashboard | IGraphsData | null> {
    const { data, error } = await Http.get<IDashboard | IGraphsData>(
      ApiUrlsAddresses.dashboard.getList(),
      undefined,
      filters
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const dashboardService = new Dashboard();
