import { ApiUrlsAddresses } from 'shared/constants';
import { IComments, ICommentFilters, IComment } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class CommentServices {
  async index(
    page: number = 1,
    perPage: number = 5,
    filters: ICommentFilters = { user: '', status: '', comment: '' }
  ): Promise<IComments | null> {
    const { data, error } = await Http.get<IComments>(
      ApiUrlsAddresses.comments.getList(page, perPage, filters)
    );
    if (error) {
      return error;
    }
    return data;
  }
  async getAll(
    filters: ICommentFilters = { user: '', status: '', comment: '' }
  ): Promise<IComment[] | null> {
    const { data, error } = await Http.get<IComment[]>(
      ApiUrlsAddresses.comments.getAll(filters)
    );
    if (error) {
      return error;
    }
    return data;
  }
  async show(comment_id: string): Promise<IComment | null> {
    const { data, error } = await Http.get<IComment>(
      ApiUrlsAddresses.comments.show(comment_id)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async update(comment: IComment): Promise<number | any> {
    const { status, error } = await Http.put<IComment, IComment>(
      ApiUrlsAddresses.comments.update(comment.secure_id || ''),
      comment
    );

    if (error) {
      return error;
    }
    return status;
  }
}

export const commentsServices = new CommentServices();
