import React from 'react';

export const contactAndReportPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/contacts',
        component: React.lazy(() => import('./contact/Pages/ContactList')),
      },
      {
        path: '/contact/:contact_id',
        component: React.lazy(() => import('./contact/Pages/ContactForm')),
      },
      {
        path: '/reports',
        component: React.lazy(() => import('./report/Pages/ReportList')),
      },
      {
        path: '/report/:report_id',
        component: React.lazy(() => import('./report/Pages/ReportForm')),
      },
      {
        path: '/contacts-and-reports/categories',
        component: React.lazy(() => import('./category/Pages/CategoryList')),
      },
      {
        path: '/contacts-and-reports/category',
        component: React.lazy(() => import('./category/Pages/CategoryForm')),
      },
      {
        path: '/contacts-and-reports/category:secure_id',
        component: React.lazy(() => import('./category/Pages/CategoryForm')),
      },
    ],
  },
];
