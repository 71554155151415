import React from 'react';

export const FAQPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/faq/faq-list',
        component: React.lazy(() => import('./Pages/FAQList')),
      },
      {
        path: '/faq/faq-view/:secure_id',
        component: React.lazy(() => import('./Pages/FAQForm')),
      },
      {
        path: '/faq/faq-view',
        component: React.lazy(() => import('./Pages/FAQForm')),
      },
    ],
  },
];
