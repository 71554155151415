import axios, { Method } from 'axios';
import Vault from './Vault';
import Config from 'config';
import { IError } from 'shared/interfaces';

export interface IRequestOptions {
  unauthorizedErrorExpected: boolean;
}

async function executeAxiosRequest<T>(
  url: string,
  method: Method,
  data: any = null,
  options?: IRequestOptions,
  customHeaders?: any,
  params?: any
): Promise<{
  data: T | null;
  status: number;
  error: IError | null;
}> {
  const headers: { [key: string]: string } = {
    ...customHeaders,
  };

  const token = Vault.getAccessToken();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await axios({
      baseURL: Config.getSetting('apiBaseUrl') as string,
      url,
      method,
      headers,
      data,
      params,
    });

    return {
      data: response.data as T,
      error: null,
      status: response.status,
    };
  } catch (error: any) {
    return {
      data: null,
      error: error?.response,
      status: 0,
    };
  }
}

export default class Http {
  static async get<T>(
    url: string,
    options?: IRequestOptions,
    params?: any
  ): Promise<{
    data: T | null;
    status: number;
    error: any;
  }> {
    return executeAxiosRequest<T>(url, 'GET', null, options, null, params);
  }

  static async post<TData, TResult>(
    url: string,
    data: TData | FormData,
    options?: IRequestOptions,
    headers?: any
  ): Promise<{
    data: TResult | any;
    error: IError | null;
    status: number;
  }> {
    return executeAxiosRequest<TResult>(url, 'POST', data, options, headers);
  }

  static async put<TData, TResult>(
    url: string,
    data: TData,
    options?: IRequestOptions,
    headers?: any
  ): Promise<{
    data: TResult | null;
    error: IError | null;
    status: number;
  }> {
    return executeAxiosRequest<TResult>(url, 'PUT', data, options, headers);
  }

  static async $delete<T>(
    url: string,
    options?: IRequestOptions
  ): Promise<{
    data: T | null;
    error: any;
    status: number;
  }> {
    return executeAxiosRequest<T>(url, 'DELETE', null, options);
  }
}
