import React from 'react';
import { Redirect } from 'react-router-dom';

import { createRoutes } from '../@crema/utility/Utils';
import { managementAdminsPagesConfig } from './managementAdmin';

import { publicAgentAndServicesPagesConfig } from './publicAgentAndServices';

import { managementUsersPagesConfig } from './managementUsersApp';

import { linkRequestPagesConfig } from './linkRequest';

import { moderationPagesConfig } from './moderation';

import { FAQPagesConfig } from './FAQ';

import { ratingPagesConfig } from './rating';

import { contactAndReportPagesConfig } from './contactAndReport';

import { errorPagesConfigs } from './errorPages';
import { authRouteConfig } from './auth';
import { initialUrl } from '../shared/constants/AppConst';

import { resetPasswordPagesConfig } from './resetPassword';

import { advertisementsPagesConfig } from './advertisements';

import { dashboardPagesConfig } from './dashboard';

const routeConfigs = [
  ...dashboardPagesConfig,
  ...authRouteConfig,
  ...errorPagesConfigs,
  ...managementAdminsPagesConfig,
  ...publicAgentAndServicesPagesConfig,
  ...managementUsersPagesConfig,
  ...linkRequestPagesConfig,
  ...moderationPagesConfig,
  ...FAQPagesConfig,
  ...contactAndReportPagesConfig,
  ...ratingPagesConfig,
  ...resetPasswordPagesConfig,
  ...advertisementsPagesConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to="/error-pages/error-404" />,
  },
];

export default routes;
