import React from 'react';

export const ratingPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/ratings',
        component: React.lazy(() => import('./Pages/RatingList')),
      },
      {
        path: '/rating/:secure_id',
        component: React.lazy(() => import('./Pages/RatingForm')),
      },
      {
        path: '/rating',
        component: React.lazy(() => import('./Pages/RatingForm')),
      },
    ],
  },
];
