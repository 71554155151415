import React from 'react';

export const publicAgentAndServicesPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/public/services',
        component: React.lazy(
          () => import('./publicServices/Pages/PublicServicesList')
        ),
      },
      {
        path: '/public/service',
        component: React.lazy(
          () => import('./publicServices/Pages/PublicServicesForm')
        ),
      },
      {
        path: '/public/service-view/:secure_id',
        component: React.lazy(
          () => import('./publicServices/Pages/PublicServicesForm')
        ),
      },
      {
        path: '/public/agents',
        component: React.lazy(
          () => import('./publicAgents/Pages/PublicAgentsList')
        ),
      },
      {
        path: '/public/agent',
        component: React.lazy(
          () => import('./publicAgents/Pages/PublicAgentsForm')
        ),
      },
      {
        path: '/public/agent-view/:secure_id',
        component: React.lazy(
          () => import('./publicAgents/Pages/PublicAgentsForm')
        ),
      },
    ],
  },
];
