import { ApiUrlsAddresses } from 'shared/constants';
import { IConfig } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class ConfigServices {
  async show(label: string): Promise<IConfig | null> {
    const { data, error } = await Http.get<IConfig>(
      ApiUrlsAddresses.configs.index(label)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async update(config: IConfig): Promise<IConfig | null> {
    const { data, error } = await Http.put<{ value: string }, IConfig>(
      ApiUrlsAddresses.configs.update(config.label || ''),
      { value: config.value }
    );
    if (error) {
      return error as any;
    }
    return data;
  }
}

export const configService = new ConfigServices();