import { IResetPassword } from 'shared/interfaces';
import { Http } from 'shared/lib';
import { ApiUrlsAddresses } from 'shared/constants';

export class ResetPasswordService {
  async store(resetPasswordProps: IResetPassword): Promise<number | any> {
    const { status, error } = await Http.post<IResetPassword, any>(
      ApiUrlsAddresses.resetPassword.store(),
      resetPasswordProps
    );
    if (error) {
      return error;
    }
    return status;
  }
}

export const resetPasswordService = new ResetPasswordService();
