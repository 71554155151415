import React from 'react';

export const resetPasswordPagesConfig = [
  {
    routes: [
      {
        path: '/reset-password/:secure_id',
        component: React.lazy(() => import('./pages/ResetPasswordForm')),
      },
    ],
  },
];
