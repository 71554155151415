import { ApiUrlsAddresses } from 'shared/constants';
import { IRating, IRatingFilters, IRatings } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class RatingServices {
  async show(rating_id: string): Promise<IRating | null> {
    const { data, error } = await Http.get<IRating>(
      ApiUrlsAddresses.ratings.show(rating_id)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getAll(
    page: number = 1,
    perPage: number = 5,
    filters: IRatingFilters
  ): Promise<IRatings | null> {
    const { data, error } = await Http.get<IRatings>(
      ApiUrlsAddresses.ratings.getList(page, perPage, filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getNoPagination(): Promise<IRating[] | null> {
    const { data, error } = await Http.get<IRating[]>(
      ApiUrlsAddresses.ratings.getAll()
    );
    if (error) {
      return error;
    }
    return data;
  }

  async store(newRating: IRating): Promise<number | any> {
    const { status, error } = await Http.post<IRating, any>(
      ApiUrlsAddresses.ratings.store(),
      newRating
    );
    if (error) {
      return error;
    }
    return status;
  }

  async update(rating: IRating): Promise<number | any> {
    const { status, error } = await Http.put<IRating, IRating>(
      ApiUrlsAddresses.ratings.update(rating.secure_id),
      rating
    );
    if (error) {
      return error;
    }
    return status;
  }

  async statusChange(rating: IRating): Promise<number | any> {
    const { error, data } = await Http.put<any, any>(
      ApiUrlsAddresses.ratings.update(rating.secure_id),
      rating
    );
    if (error) {
      return error;
    }
    return data;
  }

  async destroy(rating_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IRating>(
      ApiUrlsAddresses.ratings.destroy(rating_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }
}

export const ratingServices = new RatingServices();
