export function translateFields(field: string): string {
  const fields: any = {
    name: 'Nome completo',
    email: 'E-mail',
    password: 'Senha',
    phone: 'Telefone',
    document_pic: 'Documento funcional',
    address: 'Endereço',
    profile: 'Perfil',
    registry_type: 'Tipos de registros',
  };

  return fields[field] || field;
}
