export function translateStatus(type: string | undefined) {
  switch (type) {
    case 'accepted':
      return 'Ativo';

    case 'revised_accepted':
      return 'Revisado e Ativo';

    case 'automatic':
      return 'Automático';

    case 'banned':
      return 'Banido';

    case 'inactive':
      return 'Inativo';

    case 'inactive_by_duration':
      return 'Inativo/Duração';

    case 'inactive_by_index_limit':
      return 'Inativo/Limite Vinculação';

    case 'deleted':
      return 'Deletado';

    case 'refused':
      return 'Recusado';

    case 'revised_refused':
      return 'Revisado e Recusado';

    case 'replied':
      return 'Respondido';

    default:
      return 'Pendente';
  }
}

export function translateRoles(type: string) {
  switch (type) {
    case 'Administrator':
      return 'Administrador';

    case 'Moderator':
      return 'Moderador';

    case 'Lawyer':
      return 'Advogado';

    case 'Agent':
      return 'Agente Publico';

    case 'Service':
      return 'Serviços';

    default:
      return 'Usuário';
  }
}

export function translateRolesSlug(type: string) {
  switch (type) {
    case 'admin':
      return 'Administrador';

    case 'moderator':
      return 'Moderador';

    case 'lawyer':
      return 'Advogado';

    case 'agent':
      return 'Agente Publico';

    case 'service':
      return 'Serviços';

    default:
      return 'Usuário';
  }
}
