import { version } from 'shared/helpers/urlVersion';
import {
  ICommentFilters,
  IContactFilters,
  IContactCategoryFilters,
  IRatingFilters,
  IUserFilters,
  IEvaluationFilters,
  IFaqFilters,
  IFiltersAdvertisements,
  IPostsFilters,
} from 'shared/interfaces';

const urls = {
  auth: {
    login: () => `${version()}/oauth/token`,
    resetPassword: () => `${version()}/reset-password`,
    forgotPassword: () => `${version()}/forgot-password`,
    myProfile: () => `${version()}/myprofile`,
  },

  dashboard: {
    getList: () => `${version()}/dashboard`,
  },

  
  userinfo: {
    getList: () => `${version()}/userinfo`,
  },

  notification: {
    getList: () => `${version()}/notifications`,
  },

  adminPanel: {
    createUser: () => `${version()}/users_admin_panel`,
    updateUser: (userId: string) => `${version()}/users_admin_panel/${userId}`,
  },

  users: {
    create: () => `${version()}/users`,
    getList: (page: number, perPage: number, filters: IUserFilters) =>
      `${version()}/users?page=${page}&perPage=${perPage}&filters[role]=${
        filters['role']
      }&filters[status]=${filters['status']}&filters[lawyer]=${
        filters.type ? `{"type": "${filters.type}"} ` : ''
      }&filters[email]=${filters.email ? filters.email : ''}&filters[oab]=${
        filters.oab ? filters.oab : ''
      }`,

    getById: (userId: string) => `${version()}/users/${userId}`,
    update: (userId: string) => `${version()}/users/${userId}`,
    getAll: (filters: IUserFilters) =>
      `${version()}/users?noPagination&filters[role]=${
        filters['role']
      }&filters[status]=${filters['status']}`,
    getPermissions: (role_id: string) =>
      `${version()}/permissions?filters[role]=${role_id}`,
    destroy: (userId: string) => `${version()}/users/${userId}`,
    destroyDefinitely: (userId: string) =>
      `${version()}/users-permanent-destroy/${userId}`,
  },

  withDrawal: {
    index: (filter: any) =>
      `${version()}/withdrawal?filters[user]=${filter['user']}`,
    store: () => `${version()}/withdrawal`,
    destroy: (userId: string) => `${version()}/withdrawal/${userId}`,
  },

  clients: {
    getList: () => `${version()}/clients`,
  },

  professionals: {
    getList: () => `${version()}/professionals`,
  },

  admins: {
    getList: () => `${version()}/admins`,
  },

  publicAgents: {
    getList: (page: number, perPage: number, isLinkedUsers?: boolean) => {
      return `${version()}/agents?page=${page}&perPage=${perPage}&moderationMode&${
        isLinkedUsers !== undefined
          ? isLinkedUsers
            ? 'linkedUsers=1'
            : 'linkedUsers=0'
          : undefined
      }`;
    },
    getById: (userId: string) => `${version()}/agents/${userId}?moderationMode`,
    create: () => `${version()}/agents`,
    update: (userId: string) => `${version()}/agents/${userId}`,
    destroy: (userId: string) => `${version()}/agents/${userId}`,
    destroyDefinitely: (userId: string) =>
      `${version()}/agents-permanent-destroy/${userId}`,

    getAll: ({ isUserLinked }: { isUserLinked?: boolean }) =>
      `${version()}/agents?noPagination&${
        isUserLinked !== undefined
          ? isUserLinked
            ? 'linkedUsers=1'
            : 'linkedUsers=0&filters[status]=accepted'
          : undefined
      }`,
  },

  publicServices: {
    getList: (page: number, perPage: number, isLinkedUsers?: boolean) =>
      `${version()}/services?page=${page}&perPage=${perPage}&moderationMode&${
        isLinkedUsers !== undefined
          ? isLinkedUsers
            ? 'linkedUsers=1'
            : 'linkedUsers=0'
          : undefined
      }`,
    getById: (userId: string) =>
      `${version()}/services/${userId}?moderationMode`,
    create: () => `${version()}/services`,
    update: (userId: string) => `${version()}/services/${userId}`,
    destroy: (userId: string) => `${version()}/services/${userId}`,
    destroyDefinitely: (userId: string) =>
      `${version()}/services-permanent-destroy/${userId}`,

    getAll: ({ isUserLinked }: { isUserLinked?: boolean }) =>
      `${version()}/services?noPagination&${
        isUserLinked !== undefined
          ? isUserLinked
            ? 'linkedUsers=1'
            : 'linkedUsers=0&filters[status]=accepted'
          : undefined
      }`,
  },

  evaluations: {
    getList: (page: number, perPage: number, filters: IEvaluationFilters) =>
      `${version()}/evaluation?page=${page}&perPage=${perPage}&moderationMode&filters[userEvaluatorType]=${
        filters['userEvaluatorType']
      }&filters[status]=${filters['status']}&filters[evaluatorEmail]=${
        filters['email']
      }&filters[evaluatorOab]=${filters['oab']}&orderBy=${
        filters['orderBy']
      }&orderType=${filters['orderType']}`,
    getById: (id: string) => `${version()}/evaluation/${id}`,
    getAll: (filters: IEvaluationFilters) =>
      `${version()}/evaluation?noPagination&moderationMode&filters[userEvaluatorType]=${
        filters['userEvaluatorType']
      }&filters[status]=${filters['status']}&filters[evaluatorEmail]=${
        filters['email']
      }&filters[evaluatorOab]=${filters['oab']}&orderBy=${
        filters['orderBy']
      }&orderType=${filters['orderType']}`,

    update: (evaluationId: string) => `${version()}/evaluation/${evaluationId}`,
  },

  posts: {
    getList: (page: number, perPage: number, filters: IPostsFilters) =>
      `${version()}/posts?page=${page}&perPage=${perPage}&moderationMode&filters[status]=${
        filters['status']
      }&filters[message]=${filters['message']}&filters[user]=${
        filters['user']
      }`,
    getById: (id: string) => `${version()}/posts/${id}`,
    getAll: (filters: IPostsFilters) =>
      `${version()}/posts?noPagination&moderationMode&filters[status]=${
        filters['status']
      }&filters[message]=${filters['message']}&filters[user]=${
        filters['user']
      }`,
    update: (postId: string) => `${version()}/posts/${postId}/status`,
  },

  // comments: {
  //   getList: (page: number, perPage: number, filters: ICommentFilters) =>
  //     `${version()}/evaluation_comments?page=${page}&perPage=${perPage}&filters[type]=${
  //       filters['type']
  //     }&filters[commentator]=${filters['commentator']}&filters[status]=${
  //       filters['statusComment']
  //     }&filters[evaluation]=${filters['evaluation']}`,
  //   getAll: (filters: ICommentFilters) =>
  //     `${version()}/evaluation_comments?noPagination&filters[type]=${
  //       filters['type']
  //     }&filters[commentator]=${filters['commentator']}&filters[status]=${
  //       filters['statusComment']
  //     }`,
  //   show: (comment_id: string) =>
  //     `${version()}/evaluation_comments/${comment_id}`,
  //   update: (comment_id: string) =>
  //     `${version()}/evaluation_comments/${comment_id}`,
  // },

  comments: {
    getList: (page: number, perPage: number, filters: ICommentFilters) =>
      `${version()}/comments?page=${page}&perPage=${perPage}&filters[comment]=${
        filters['comment']
      }&filters[status]=${filters['status']}&filters[user]=${filters['user']}`,
    getAll: (filters: ICommentFilters) =>
      `${version()}/comments?noPagination&filters[comment]=${
        filters['comment']
      }&filters[status]=${filters['status']}&filters[user]=${filters['user']}`,
    show: (comment_id: string) => `${version()}/comments/${comment_id}`,
    update: (comment_id: string) =>
      `${version()}/comments/${comment_id}/status`,
  },

  FAQ: {
    getAll: () => `${version()}/faq?noPagination`,
    getList: (page: number, perPage: number, filters: IFaqFilters) =>
      `${version()}/faq?page=${page}&perPage=${perPage}&filters[category]=${
        filters.category
      }`,
    store: () => `${version()}/faq`,
    update: (faqId: string) => `${version()}/faq/${faqId}`,
    show: (faqId: string) => `${version()}/faq/${faqId}`,
    destroy: (faqId: string) => `${version()}/faq/${faqId}`,
  },

  FAQCategories: {
    index: () => `${version()}/faq_category`,
    show: (faqId: string) => `${version()}/faq_category/${faqId}`,
    store: () => `${version()}/faq_category`,
    update: (faqId: string) => `${version()}/faq_category/${faqId}`,
    destroy: (faqId: string) => `${version()}/faq_category/${faqId}`,
  },

  contacts: {
    getAll: () => `${version()}/contact?noPagination&filters[type]=common`,
    getList: (page: number, perPage: number, filters: IContactFilters) =>
      `${version()}/contact?page=${page}&perPage=${perPage}&filters[status]=${
        filters.status
      }&filters[category]=${filters.category}&filters[roles]=${
        filters.roles
      }&filters[type]=common`,
    store: () => `${version()}/contact`,
    update: (contactId: string) => `${version()}/contact/${contactId}`,
    show: (contactId: string) => `${version()}/contact/${contactId}`,
    destroy: (contactId: string) => `${version()}/contact/${contactId}`,
  },

  reports: {
    getAll: () => `${version()}/contact?noPagination&filters[type]=report`,
    getList: (page: number, perPage: number, filters: IContactFilters) =>
      `${version()}/contact?page=${page}&perPage=${perPage}&filters[status]=${
        filters.status
      }&filters[category]=${filters.category}&filters[roles]=${
        filters.roles
      }&filters[type]=report`,
    store: () => `${version()}/contact`,
    update: (contactId: string) => `${version()}/contact/${contactId}`,
    show: (contactId: string) => `${version()}/contact/${contactId}`,
    destroy: (contactId: string) => `${version()}/contact/${contactId}`,
  },

  contactCategories: {
    getAll: ({ common, report }: { common?: boolean; report?: boolean }) =>
      `${version()}/contact_category?noPagination&${
        common && 'filters[type]=common'
      }&${report && 'filters[type]=report'}`,
    getList: (
      page: number,
      perPage: number,
      filters: IContactCategoryFilters
    ) =>
      `${version()}/contact_category?page=${page}&perPage=${perPage}&filters[type]=${
        filters.type
      }`,
    store: () => `${version()}/contact_category`,
    update: (contactCategoryID: string) =>
      `${version()}/contact_category/${contactCategoryID}`,
    show: (contactCategoryID: string) =>
      `${version()}/contact_category/${contactCategoryID}`,
    destroy: (contactCategoryID: string) =>
      `${version()}/contact_category/${contactCategoryID}`,
  },

  ratings: {
    getAll: () => `${version()}/rating?noPagination`,
    getList: (page: number, perPage: number, filters: IRatingFilters) =>
      `${version()}/rating?page=${page}&perPage=${perPage}&filters[category]=${
        filters.category
      }`,
    store: () => `${version()}/rating`,
    update: (rating_id: string) => `${version()}/rating/${rating_id}`,
    show: (rating_id: string) => `${version()}/rating/${rating_id}`,
    destroy: (rating_id: string) => `${version()}/rating/${rating_id}`,
  },

  ratingCategories: {
    index: () => `${version()}/rating_category`,
    show: (rating_category_id: string) =>
      `${version()}/rating_category/${rating_category_id}`,
    store: () => `${version()}/rating_category`,
    update: (rating_category_id: string) =>
      `${version()}/rating_category/${rating_category_id}`,
    destroy: (rating_category_id: string) =>
      `${version()}/rating_category/${rating_category_id}`,
  },

  resetPassword: {
    store: () => `${version()}/reset-password-email`,
  },

  forgotPassword: {
    store: () => `${version()}/forgot-password-email`,
  },

  advertisements: {
    index: (page: number, perPage: number, filters: IFiltersAdvertisements) =>
      `${version()}/adv?page=${page}&perPage=${perPage}&filters[status]=${
        filters['status'] ?? ''
      }`,

    create: () => `${version()}/adv`,
    show: (secure_id: string) => `${version()}/adv/${secure_id}`,
    update: (secure_id: string) => `${version()}/adv/${secure_id}`,
    destroy: (secure_id: string) => `${version()}/adv/${secure_id}`,
  },

  configs: {
    index: (label: string) => `${version()}/globalConfig/${label}`,
    update: (label: string) => `${version()}/globalConfig/${label}`,
  },
};
export default urls;
