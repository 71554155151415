import { ApiUrlsAddresses } from 'shared/constants';
import { IError, IPublicService, IPublicServices } from 'shared/interfaces';
import { Http } from 'shared/lib';

class PublicServiceServices {
  async index(
    page: number = 1,
    perPage: number = 5,
    filters: any,
    isLinkedUsers?: boolean
  ): Promise<IPublicServices | null> {
    const { data, error } = await Http.get<IPublicServices>(
      ApiUrlsAddresses.publicServices.getList(page, perPage, isLinkedUsers),
      undefined,
      filters
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getNoPagination(): Promise<IPublicService[] | null> {
    const { data, error } = await Http.get<IPublicService[]>(
      ApiUrlsAddresses.publicServices.getAll({})
    );
    if (error) {
      return error;
    }
    return data;
  }

  async show(id: string): Promise<IPublicService | null> {
    const { data, error } = await Http.get<IPublicService>(
      ApiUrlsAddresses.publicServices.getById(id)
    );
    if (error) {
      return error;
    }
    return data;
  }
  async store(newUser: IPublicService): Promise<any | IError | null> {
    const { status, error } = await Http.post<IPublicService, IError>(
      ApiUrlsAddresses.publicServices.create(),
      newUser
    );
    if (error) {
      return error;
    }
    return status;
  }
  async update(user: IPublicService): Promise<any | IError | null> {
    const { status, error } = await Http.put<IPublicService, IPublicService>(
      ApiUrlsAddresses.publicServices.update(user.secure_id || ''),
      user
    );
    if (error) {
      return error;
    }
    return status;
  }
  async destroy(user_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IPublicService>(
      ApiUrlsAddresses.publicServices.destroy(user_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }

  async destroyDefinitely(user_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IPublicService>(
      ApiUrlsAddresses.publicServices.destroyDefinitely(user_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }

  async indexNoLinkedUser(filter: string): Promise<IPublicService[] | null> {
    const { data, error } = await Http.get<IPublicService[]>(
      ApiUrlsAddresses.publicServices.getAll({ isUserLinked: false })
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const publicServiceServices = new PublicServiceServices();
