import { ApiUrlsAddresses } from 'shared/constants';
import { IFAQ, IFAQs, IFaqFilters } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class FAQServices {
  async show(faqID: string): Promise<IFAQ | null> {
    const { data, error } = await Http.get<IFAQ>(
      ApiUrlsAddresses.FAQ.show(faqID)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getAll(
    page: number = 1,
    perPage: number = 5,
    filters: IFaqFilters
  ): Promise<IFAQs | null> {
    const { data, error } = await Http.get<IFAQs>(
      ApiUrlsAddresses.FAQ.getList(page, perPage, filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getNoPagination(): Promise<IFAQ[] | null> {
    const { data, error } = await Http.get<IFAQ[]>(
      ApiUrlsAddresses.FAQ.getAll()
    );
    if (error) {
      return error;
    }
    return data;
  }

  async store(newFAQ: IFAQ): Promise<number | any> {
    const { status, error } = await Http.post<IFAQ, any>(
      ApiUrlsAddresses.FAQ.store(),
      newFAQ
    );
    if (error) {
      return error;
    }
    return status;
  }

  async update(FAQ: IFAQ): Promise<number | any> {
    const { status, error } = await Http.put<IFAQ, IFAQ>(
      ApiUrlsAddresses.FAQ.update(FAQ.secure_id),
      FAQ
    );
    if (error) {
      return error;
    }
    return status;
  }

  async statusChange(FAQ: IFAQ): Promise<number | any> {
    const { error, data } = await Http.put<any, any>(
      ApiUrlsAddresses.FAQ.update(FAQ.secure_id),
      FAQ
    );
    if (error) {
      return error;
    }
    return data;
  }

  async destroy(faqID: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IFAQ>(
      ApiUrlsAddresses.FAQ.destroy(faqID)
    );
    if (error) {
      return error;
    }
    return !!data;
  }
}

export const faqServices = new FAQServices();
