import React from 'react';

export const moderationPagesConfig = [
  {
    auth: ['user'],
    routes: [
      // {
      //   path: '/moderation/rejoinders',
      //   component: React.lazy(
      //     () => import('./rejoinders/Pages/RejoindersList')
      //   ),
      // },
      // {
      //   path: '/moderation/rejoinder/:comment_id',
      //   component: React.lazy(() => import('./rejoinders/Pages/RejoinderForm')),
      // },

      // {
      //   path: '/moderation/replicas',
      //   component: React.lazy(() => import('./replicas/Pages/ReplicasList')),
      // },
      // {
      //   path: '/moderation/replica/:comment_id',
      //   component: React.lazy(() => import('./replicas/Pages/ReplicaForm')),
      // },

      // {
      //   path: '/moderation/evaluations',
      //   component: React.lazy(
      //     () => import('./evaluations/Pages/EvaluationList')
      //   ),
      // },
      // {
      //   path: '/moderation/evaluation/:evaluation_id',
      //   component: React.lazy(
      //     () => import('./evaluations/Pages/EvaluationForm')
      //   ),
      // },

      {
        path: '/moderation/posts',
        component: React.lazy(() => import('./posts/Pages/PostList')),
      },
      {
        path: '/moderation/post/:post_id',
        component: React.lazy(() => import('./posts/Pages/PostForm')),
      },

      {
        path: '/moderation/comments',
        component: React.lazy(() => import('./comments/Pages/CommentList')),
      },
      {
        path: '/moderation/comments-view/:comment_id',
        component: React.lazy(() => import('./comments/Pages/CommentForm')),
      },
    ],
  },
];
