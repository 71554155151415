import { ApiUrlsAddresses } from 'shared/constants';
import { IContact, IContactFilters, IContacts } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class ContactServices {
  async show(contactID: string): Promise<IContact | null> {
    const { data, error } = await Http.get<IContact>(
      ApiUrlsAddresses.contacts.show(contactID)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getAll(
    page: number = 1,
    perPage: number = 5,
    filters: IContactFilters
  ): Promise<IContacts | null> {
    const { data, error } = await Http.get<IContacts>(
      ApiUrlsAddresses.contacts.getList(page, perPage, filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getNoPagination(): Promise<IContact[] | null> {
    const { data, error } = await Http.get<IContact[]>(
      ApiUrlsAddresses.contacts.getAll()
    );
    if (error) {
      return error;
    }
    return data;
  }

  async store(newContact: IContact): Promise<number | any> {
    const { status, error } = await Http.post<IContact, any>(
      ApiUrlsAddresses.contacts.store(),
      newContact
    );
    if (error) {
      return error;
    }
    return status;
  }

  async update(contacts: IContact): Promise<number | any> {
    const { status, error } = await Http.put<IContact, IContact>(
      ApiUrlsAddresses.contacts.update(contacts.secure_id),
      contacts
    );
    if (error) {
      return error;
    }
    return status;
  }
}

export const contactServices = new ContactServices();
