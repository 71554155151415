import { ApiUrlsAddresses } from 'shared/constants';
import { IUser } from 'shared/interfaces';
import { Http } from 'shared/lib';

export class WithDrawalServices {
  async index(filters: any = ''): Promise<any | null> {
    const { data, error } = await Http.get<any>(
      ApiUrlsAddresses.withDrawal.index(filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async store(newWithDrawal: any): Promise<any | any[]> {
    const { data, error } = await Http.post<any, any>(
      ApiUrlsAddresses.withDrawal.store(),
      newWithDrawal
    );
    if (error) {
      return error;
    }
    return data;
  }

  async destroy(user_id: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IUser>(
      ApiUrlsAddresses.withDrawal.destroy(user_id)
    );
    if (error) {
      return error;
    }
    return !!data;
  }
}

export const withDrawalServices = new WithDrawalServices();
