import React from 'react';

export const managementUsersPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/users-app/lawyers',
        component: React.lazy(() => import('./Lawyers/Pages/LawyersList')),
      },
      {
        path: '/users-app/lawyer/:secure_id',
        component: React.lazy(() => import('./Lawyers/Pages/LawyerView')),
      },
      {
        path: '/users-app/agents',
        component: React.lazy(
          () => import('./PublicAgents/Pages/PublicAgentsList')
        ),
      },
      {
        path: '/users-app/agent/:secure_id',
        component: React.lazy(
          () => import('./PublicAgents/Pages/PublicAgentView')
        ),
      },
      {
        path: '/users-app/services',
        component: React.lazy(
          () => import('./PublicServices/Pages/PublicServiceList')
        ),
      },
      {
        path: '/users-app/service/:secure_id',
        component: React.lazy(
          () => import('./PublicServices/Pages/PublicServiceView')
        ),
      },
    ],
  },
];
