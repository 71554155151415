export function tagsColorStatus(status: string | undefined) {
  switch (status) {
    case 'accepted':
      return '#2e7d32';

    case 'revised_accepted':
      return '#2e7d32';

    case 'automatic':
      return '#0A8FDC';

    case 'banned':
      return '#b71c1c';

    case 'inactive':
      return '#9e9e9e';

    case 'inactive_by_duration':
      return '#9e9e9e';

    case 'inactive_by_index_limit':
      return '#9e9e9e';

    case 'refused':
      return '#b71c1c';

    case 'revised_refused':
      return '#b71c1c';

    case 'deleted':
      return '#000000';

    case 'replied':
      return '#2e7d32';

    default:
      return '#f57f17';
  }
}
