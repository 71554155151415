import { ApiUrlsAddresses } from 'shared/constants';
import { IEvaluation, IEvaluations } from 'shared/interfaces';
import { IEvaluationFilters } from 'shared/interfaces/IEvaluation';
import { Http } from 'shared/lib';

export class EvaluationServices {
  async index(
    page: number = 1,
    perPage: number = 5,
    // eslint-disable-next-line no-restricted-globals
    filters: IEvaluationFilters = { userEvaluatorType: '', status: '' }
  ): Promise<IEvaluations | null> {
    const { data, error } = await Http.get<IEvaluations>(
      ApiUrlsAddresses.evaluations.getList(page, perPage, filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async fetchAllEvaluations(
    // eslint-disable-next-line no-restricted-globals
    filters: IEvaluationFilters = { userEvaluatorType: '', status: '' }
  ): Promise<IEvaluation[] | null> {
    const { data, error } = await Http.get<IEvaluation[]>(
      ApiUrlsAddresses.evaluations.getAll(filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async show(id: string): Promise<IEvaluation | null> {
    const { data, error } = await Http.get<IEvaluation>(
      ApiUrlsAddresses.evaluations.getById(id)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async update(evaluation: IEvaluation): Promise<IEvaluation | any> {
    const { data, error } = await Http.put<IEvaluation, IEvaluation>(
      ApiUrlsAddresses.evaluations.update(evaluation.secure_id || ''),
      evaluation
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const evaluationsServices = new EvaluationServices();
