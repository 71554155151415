import { ApiUrlsAddresses } from 'shared/constants';
import {
  IContactCategory,
  IContactCategories,
  IContactCategoryFilters,
} from 'shared/interfaces';
import { Http } from 'shared/lib';

export class ContactCategoriesServices {
  async show(contactID: string): Promise<IContactCategory | null> {
    const { data, error } = await Http.get<IContactCategory>(
      ApiUrlsAddresses.contactCategories.show(contactID)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getAll(
    page: number = 1,
    perPage: number = 5,
    filters: IContactCategoryFilters
  ): Promise<IContactCategories | null> {
    const { data, error } = await Http.get<IContactCategories>(
      ApiUrlsAddresses.contactCategories.getList(page, perPage, filters)
    );
    if (error) {
      return error;
    }
    return data;
  }

  async getNoPagination({
    common,
    report,
  }: {
    common?: boolean;
    report?: boolean;
  }): Promise<IContactCategory[] | null> {
    const { data, error } = await Http.get<IContactCategory[]>(
      ApiUrlsAddresses.contactCategories.getAll({ common, report })
    );
    if (error) {
      return error;
    }
    return data;
  }

  async store(newContact: IContactCategory): Promise<number | any> {
    const { status, error } = await Http.post<IContactCategory, any>(
      ApiUrlsAddresses.contactCategories.store(),
      newContact
    );
    if (error) {
      return error;
    }
    return status;
  }

  async update(contactCategories: IContactCategory): Promise<number | any> {
    const { status, error } = await Http.put<
      IContactCategory,
      IContactCategory
    >(
      ApiUrlsAddresses.contactCategories.update(contactCategories.secure_id),
      contactCategories
    );
    if (error) {
      return error;
    }
    return status;
  }

  async destroy(contactCategoryID: string): Promise<boolean> {
    const { data, error } = await Http.$delete<IContactCategory>(
      ApiUrlsAddresses.contactCategories.destroy(contactCategoryID)
    );
    if (error) {
      console.log(error);

      return error;
    }
    return !!data;
  }

  async statusChange(contactCategory: IContactCategory): Promise<number | any> {
    const { error, data } = await Http.put<any, any>(
      ApiUrlsAddresses.contactCategories.update(contactCategory.secure_id),
      contactCategory
    );
    if (error) {
      return error;
    }
    return data;
  }
}

export const contactCategoriesServices = new ContactCategoriesServices();
